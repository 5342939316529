var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "backend" }, [
    _c("main", [
      _c("div", { staticClass: "container" }, [
        _vm.loading || _vm.redirect
          ? _c("div", { staticClass: "loading-container" }, [_c("Loader")], 1)
          : !_vm.loading && _vm.data
          ? _c("div", { staticClass: "main-content" }, [
              _c("div", { staticClass: "club-info text-center" }, [
                _c("h1", { staticClass: "mt-5 h2" }, [
                  _vm._v(_vm._s(_vm.data.clubName)),
                ]),
              ]),
              _c("div", { staticClass: "divider mt-2 mb-5" }),
              _c("div", { staticClass: "row text-left" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "b-overlay",
                      { attrs: { rounded: "sm" } },
                      [
                        _c(
                          "b-card",
                          {
                            attrs: {
                              title: _vm.getTitle(_vm.data.previewOnly),
                            },
                          },
                          [
                            _c("div", { staticClass: "mt-3 mb-3" }, [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "1.25rem" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.data.roleName) +
                                      " " +
                                      _vm._s(_vm.$t("dutyReminder.on")) +
                                      " " +
                                      _vm._s(
                                        _vm.getDayName(_vm.data.startTime)
                                      ) +
                                      " " +
                                      _vm._s(_vm.getTime(_vm.data.startTime)) +
                                      " - " +
                                      _vm._s(_vm.getTime(_vm.data.endTime)) +
                                      " (" +
                                      _vm._s(_vm.data.assigmentsCount) +
                                      "/" +
                                      _vm._s(_vm.data.assigmentsTotal) +
                                      ")"
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "buttons-section" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "outline-secondary",
                                      disabled: _vm.assignLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goBack()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("dutyReminder.backHome"))
                                    ),
                                  ]
                                ),
                                !_vm.data.previewOnly
                                  ? _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "success",
                                          disabled: _vm.assignLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.assign()
                                          },
                                        },
                                      },
                                      [
                                        _vm.assignLoading
                                          ? _c("b-spinner", {
                                              staticClass: "mr-2",
                                              attrs: { small: "" },
                                            })
                                          : _vm._e(),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("dutyReminder.save"))
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
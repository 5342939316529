






































import { inject } from 'inversify-props';
import { Vue, Component, Watch } from 'vue-property-decorator';

import { ToastHelper } from '@/shared/helpers/toast.helper';
import { IDutyApiService, identifier } from '../services/duty-api.service';
import { DutyReminderAcceptance } from '../models/duty_reminder_acceptance.model';
import { Member } from '@/modules/login/models/member.model';
import { Getter } from 'vuex-class';
import dayjs from 'dayjs';

@Component({})
export default class DutyReminder extends Vue {

    private loading = false;
    private assignLoading = false;
    private redirect = false;

    private data: DutyReminderAcceptance | null = null;

    @Getter
    currentMember!: Member;
    
    @inject(identifier)
    private dutyApiService! : IDutyApiService;

    get token() : string {
        return this.$route.params.token;
    }

    async created() : Promise<void> {
        if (this.token) {
            await this.getDutyReminderInfo();
        }
        else {
            this.$router.push({ name: 'NotFound' })
        }
    }

    async getDutyReminderInfo() : Promise<void> {
        try {
            this.loading = true;

            const response = await this.dutyApiService.getDutyReminderToAssign(this.token);
            

            if (this.currentMember && this.token && !response.previewOnly) {
                this.redirect = true;
                this.$store.commit('setDutyReminderToken', this.token);
                this.$router.push({ name: 'Duties' })
            }

            this.data = response;
        }
        catch (e) {
            ToastHelper.showError(this.$bvToast, this.$t('shared.toast.error'));
        }
        finally {
            this.loading = false;
        }
    }

    goBack() : void {
        this.$router.push({ name: 'Login' });
    }

    async assign() : Promise<void>{
        try {
            this.assignLoading = true;
            const result = await this.dutyApiService.assignToDutyAnonymous(this.token);
            
            if (!result) {
                ToastHelper.showError(this.$bvToast, this.$t('shared.toast.error'));
            }
            else {
                ToastHelper.showSuccess(this.$bvToast, 'Assigned');
                this.$router.push({ name: 'Login' });
            }
        }
        catch (e) {
            const errorMsg = (e as any)?.response.data?.Item1;

            if (errorMsg && (e as any)?.response.status === 403) {
                ToastHelper.showError(this.$bvToast, errorMsg);
            }
            else {
                ToastHelper.showError(this.$bvToast, this.$t('shared.toast.error'));
            }
        }
        finally {
            this.assignLoading = false;
        }
    }

    getTitle (preview: boolean) : string {
        let title = `${this.$t('dutyReminder.title')}`;

        if (preview) {
            title += ` (${this.$t('dutyReminder.preview')})`;
        }

        if (this.data?.personName) {
            title += ` ${this.$t('dutyReminder.as')} ${this.data?.personName}`;
        }

        return title;
    }

    getDayName (date: Date) : string {
        return dayjs(date).format('dddd DD MMMM');
    }

    getTime (date: Date) : string {
        return dayjs(date).utc().format('HH:mm')
    }

    @Watch('token')
    async onTokenChange() : Promise<void> {
        if (this.token) {
            await this.getDutyReminderInfo();
        }
        else {
            this.$router.push({ name: 'NotFound' })
        }
    }
}
